export default function() {
	return {

		init() {
			const page = document.querySelector('.partner-page');

			if (!page) {
				return;
			}


			window.addEventListener('resize', () => {
				if (!page) {
					return;
				}	
				if(window.innerWidth < 640) {
					page.style.marginTop = '140px';
				} else {
					this.setMargin(page);
				}
			});

			this.setMargin(page);
			// trigger resize event
			window.dispatchEvent(new Event('resize'));
			
		},
		setMargin(page) {
			const viewHeight = window.innerHeight;
			const partnerPhoto = document.querySelector('.partner-photo');
			if (!partnerPhoto) {
				return;
			}
			const photoHeight = partnerPhoto.clientHeight;
			const firstTwoRowHeight = photoHeight * 2 + 92;
			const delta = viewHeight - firstTwoRowHeight;
			page.style.marginTop = delta + 'px';

		}
	};
}