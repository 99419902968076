import Swiper from 'swiper';
import { Pagination, Mousewheel } from 'swiper/modules';
import SlideProgress from './SlideProgress';

export default function() {
	return {
		swiper: null,
		id: 'image-blocks',
		current: 0,
		setup(id) {	
			this.id = id;
			this._init();
		},
		_init() {
			// console.log('init image blocks');
			let progress = null;
			let slideshow = document.getElementById(this.id);
			if(!slideshow) {
				return;
			}
			let swiperElement = slideshow.querySelector('.swiper');

			Swiper.use([Pagination, Mousewheel]);
			this.swiper = new Swiper(swiperElement, {
				loop: true,
				direction: "horizontal",
				speed: 1000,
				slidesPerView: 1,
				spaceBetween: 30,
				mousewheel: false,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				on: {
					init: function() {
						// progress = new SlideProgress(slideshow, this);
						// console.log('init image blocks swiper');
					},
					afterInit: function() {
						progress = new SlideProgress(slideshow, this);
						progress.updateProgress();
					},
					slideChange: () => {
						if(progress) {
							progress.updateProgress();
							// console.log('slide change', this.swiper.realIndex);
							this.$dispatch('imageblocks', this.swiper.realIndex);
							this.current = this.swiper.realIndex;
						}
						
						
					}
				},
				breakpoints: {
					1024: {
						direction: "vertical",
					}
				}
			});

			window.addEventListener('resize', () => {
				this.resize();
			});
			this.resize();

			window.addEventListener('changeslide', (e) => {
				// console.log('change slide from event', e.detail);
				this.goToSlide(e.detail);
				// scroll to top swiper element 
				// get boxrect of swiper element
				const boxrect = swiperElement.getBoundingClientRect();


				// console.log('scroll to top swiper element', boxrect);
				window.scrollTo({
					top: boxrect.y,
					behavior: 'smooth'
				});

			});
		},
		resize() {
			if (window.innerWidth < 1024) {
				this.swiper.mousewheel.disable();
			} else {
				this.swiper.mousewheel.enable();
			}

			this.swiper.update();
		},
		goToSlide(index) {
			if(index > this.current) {
				this.next();
			} else if(index < this.current) {
				this.previous();
			}

			// this.swiper.slideTo(index);
		},
		previous() {
			// console.log('previous');
			this.swiper.slidePrev();
		},
		next() {
			// console.log('next');
			this.swiper.slideNext();
		},
		playVideo(id) {
			// console.log('play video', id);
			const burger = document.querySelector('.header .hamburger');
			const youtubeUrl = 'https://www.youtube-nocookie.com/embed/';
			const modal = document.querySelector('.video-modal');
			const iframe = modal.querySelector('iframe');
			modal.classList.remove('hidden');
			iframe.src = `${youtubeUrl}${id}?autoplay=1`;
			burger.classList.add('hidden');
		},
		closeVideo() {
			const burger = document.querySelector('.header .hamburger');
			const modal = document.querySelector('.video-modal');
			const iframe = modal.querySelector('iframe');
			modal.classList.add('hidden');
			iframe.src = '';
			burger.classList.remove('hidden');
		}		
	};
}
