export default function() {
	return {
		current: 0,
		init() {
			// console.log('Careers init()');
		},
		onImageBlocksChanged(e) {
			// console.log('Careers onImageBlocksChanged', e.detail);
			this.current = e.detail;
		},
		changeSlide(id) {
			console.log('Careers changeSlide', id);
			this.current = id;
			this.$dispatch('changeslide', id);
		}
	};
}